var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: this.prevRoute } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          _c(
            "CForm",
            [
              _c(
                "div",
                {
                  staticClass:
                    "order-header-container list-group-item shadow mb-3",
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-start flex-gap-2 mb-3",
                      },
                      [
                        _c(
                          "CLink",
                          {
                            attrs: {
                              to: {
                                name: "User",
                                params: { id: _vm.order.user?.id || 0 },
                              },
                            },
                          },
                          [
                            _c("CImg", {
                              staticClass: "c-avatar-img",
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src:
                                  _vm.order.user?.image_icon ||
                                  _vm.order.user?.gravatar,
                                placeholderColor: "lightgray",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "lead font-weight-bold text-dark ml-1",
                              },
                              [_vm._v(_vm._s(_vm.order.roUseName))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "CLink",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.copyTextToClipboard(
                                  `${_vm.order.roUseName}\n${_vm.order.roMobile}\n${_vm.order.roPostal_code} ${_vm.order.roCity}\n${_vm.order.roAddress}`
                                )
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-copy" } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("dl", [
                      _vm.order.roCompanyName
                        ? _c(
                            "dt",
                            { staticClass: "text-info" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-1",
                                attrs: { name: "cil-building" },
                              }),
                              _vm._v(_vm._s(_vm.order.roCompanyName)),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "dt",
                        [
                          _c("CIcon", {
                            staticClass: "mr-2",
                            attrs: { name: "cil-at" },
                          }),
                          _vm.showEmail
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.order.user?.email)),
                              ])
                            : _c("span", [_vm._v(_vm._s(_vm.maskedEmail))]),
                          _c(
                            "CLink",
                            {
                              staticClass: "m-2",
                              on: {
                                click: function ($event) {
                                  _vm.showEmail = !_vm.showEmail
                                },
                              },
                            },
                            [
                              _vm.showEmail
                                ? _c("CIcon", {
                                    attrs: { name: "cil-lock-unlocked" },
                                  })
                                : _c("CIcon", {
                                    attrs: { name: "cil-lock-locked" },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "CLink",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyTextToClipboard(
                                    _vm.order.user?.email
                                  )
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-copy" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "dt",
                        [
                          _c("CIcon", {
                            staticClass: "mr-2",
                            attrs: { name: "cil-mobile" },
                          }),
                          _vm._v(_vm._s(_vm.order.roMobile) + " "),
                          _c(
                            "CLink",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyTextToClipboard(
                                    _vm.order.roMobile
                                  )
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-copy" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "dt",
                        [
                          _c("CIcon", {
                            staticClass: "mr-1",
                            attrs: { name: "cil-location-pin" },
                          }),
                          _vm._v(
                            _vm._s(_vm.order.roPostal_code) +
                              " (" +
                              _vm._s(_vm.order.roDistance) +
                              ") " +
                              _vm._s(_vm.order.roCity)
                          ),
                        ],
                        1
                      ),
                      _c("dd", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v(_vm._s(_vm.order.roAddress)),
                      ]),
                    ]),
                  ]),
                  _vm.order.deliveryservice
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "order-ds flex-grow-1 thin-scrollbar mb-1 mb-lg-0",
                        },
                        [
                          _c("p", { staticClass: "lead font-weight-bold" }, [
                            _vm._v("Menute Delivery Timeline"),
                          ]),
                          !_vm.confirmationTime ||
                          !_vm.order.delivery?.created_at_db
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column flex-gap-2 lead",
                                },
                                [
                                  !_vm.confirmationTime
                                    ? _c(
                                        "span",
                                        { staticClass: "lead text-warning" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "flash-it" },
                                            [_vm._v("⚠")]
                                          ),
                                          _vm._v(
                                            "The order has not been confirmed yet!"
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column flex-gap-2",
                                        },
                                        [
                                          new Date(
                                            _vm.order.roAproxDeliveryDateToCourier
                                          ) < new Date()
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  { staticClass: "flash-it" },
                                                  [_vm._v("⚠")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " The order has not yet been assigned to courier, please check! It must be assigned as of "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          new Date(
                                                            _vm.order.roAproxDeliveryDateToCourier
                                                          ).toLocaleString()
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(". "),
                                                  ]
                                                ),
                                              ])
                                            : _c(
                                                "span",
                                                {
                                                  staticClass: "lead text-info",
                                                },
                                                [
                                                  _vm._v(
                                                    " 🛈 The earliest time it will be assigned to the courier "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        new Date(
                                                          _vm.order.roAproxDeliveryDateToCourier
                                                        ).toLocaleString()
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "align-self-center",
                                              attrs: {
                                                color: "info",
                                                shape: "pill",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.$store.state.transferOrRevokeOrderShow = true
                                                },
                                              },
                                            },
                                            [
                                              _c("CIcon", {
                                                staticClass: "mr-2",
                                                attrs: { name: "cil-transfer" },
                                              }),
                                              _vm._v("Assign to courier "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-self-center align-items-center flex-gap-1",
                                    },
                                    [
                                      _c(
                                        "small",
                                        {
                                          class: {
                                            "text-warning":
                                              _vm.order.courierCannotBeAssigned,
                                            "text-muted":
                                              !_vm.order
                                                .courierCannotBeAssigned,
                                          },
                                        },
                                        [_vm._v("Courier cannot be assigned:")]
                                      ),
                                      _c("CSwitch", {
                                        attrs: {
                                          labelOn: "Yes",
                                          labelOff: "No",
                                          color: "warning",
                                          size: "sm",
                                          shape: "pill",
                                          checked:
                                            _vm.order.courierCannotBeAssigned,
                                        },
                                        on: {
                                          "update:checked": [
                                            function ($event) {
                                              return _vm.$set(
                                                _vm.order,
                                                "courierCannotBeAssigned",
                                                $event
                                              )
                                            },
                                            () =>
                                              _vm.updateDeliveryServiceDetails(
                                                _vm.order
                                              ),
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c("div", { staticClass: "timeline-container" }, [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "order-courier-container text-center",
                                  },
                                  [
                                    _vm.order.delivery?.courier
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "delivery-details" },
                                            [
                                              _vm._v(
                                                " Assigned to " +
                                                  _vm._s(
                                                    _vm.order.delivery?.courier
                                                      ?.first_name
                                                  ) +
                                                  " (#" +
                                                  _vm._s(
                                                    _vm.order.delivery?.courier
                                                      ?.id
                                                  ) +
                                                  ") "
                                              ),
                                              _vm.order.delivery?.dispatcher
                                                ? _c("span", [
                                                    _vm._v(
                                                      "by " +
                                                        _vm._s(
                                                          _vm.order.delivery
                                                            ?.dispatcher
                                                            .first_name
                                                        ) +
                                                        " (#" +
                                                        _vm._s(
                                                          _vm.order.delivery
                                                            ?.dispatcher.id
                                                        ) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "courier-details flex-row justify-content-center",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: `mailto:${_vm.order.delivery?.courier?.email}`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.order.delivery
                                                        ?.courier?.email
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v("  📱 "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: `tel:${_vm.order.delivery?.courier?.mobile}`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.order.delivery
                                                        ?.courier?.mobile
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.order.delivery?.dispatcher &&
                                        !_vm.order.delivery?.courier
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "delivery-details",
                                              },
                                              [
                                                _vm._v(
                                                  " Assigned to dispatcher " +
                                                    _vm._s(
                                                      _vm.order.delivery
                                                        ?.dispatcher.first_name
                                                    ) +
                                                    " (#" +
                                                    _vm._s(
                                                      _vm.order.delivery
                                                        ?.dispatcher.id
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "courier-details flex-row justify-content-center",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: `mailto:${_vm.order.delivery?.dispatcher.email}`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.delivery
                                                          ?.dispatcher.email
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v("  📱 "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: `tel:${_vm.order.delivery?.dispatcher.mobile}`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.delivery
                                                          ?.dispatcher.mobile
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "waiting-assignment my-2 text-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "flash-it" },
                                                  [_vm._v("⌛")]
                                                ),
                                                _vm._v(
                                                  " Waiting for courier assignment by dispatcher... "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "CButton",
                                              {
                                                staticClass:
                                                  "align-self-center",
                                                attrs: {
                                                  color: "info",
                                                  shape: "pill",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.$store.state.transferOrRevokeOrderShow = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("CIcon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    name: "cil-transfer",
                                                  },
                                                }),
                                                _vm._v("Transfer or Revoke "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.order.delivery?.courier
                                  ? _c(
                                      "div",
                                      { staticClass: "timeline mb-5" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "event has-sub",
                                            class: {
                                              done: _vm.order.delivery
                                                ?.confirmed,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "circle" },
                                              [
                                                _c("h4", [_vm._v("Assigned")]),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      new Date(
                                                        _vm.order.delivery?.created_at_db
                                                      ).toLocaleString()
                                                    )
                                                  ),
                                                ]),
                                                _vm.order.delivery
                                                  ?.arrived_at_restaurant ===
                                                null
                                                  ? _c(
                                                      "CLink",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "c-tooltip",
                                                            rawName:
                                                              "v-c-tooltip",
                                                            value: {
                                                              content:
                                                                "Transfer or revoke order",
                                                              placement:
                                                                "right",
                                                            },
                                                            expression:
                                                              "{ content: 'Transfer or revoke order', placement: 'right' }",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "align-self-baseline",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.$store.state.transferOrRevokeOrderShow = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("CIcon", {
                                                          attrs: {
                                                            name: "cil-transfer",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "sub-event",
                                                    class: {
                                                      done: _vm.order.delivery
                                                        ?.confirmed,
                                                    },
                                                  },
                                                  [
                                                    _c("h6", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                    _vm.order.delivery
                                                      ?.confirmed
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-muted",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.humanizeDateDifference(
                                                                    _vm.order
                                                                      .delivery
                                                                      ?.created_at_db,
                                                                    _vm.order
                                                                      .delivery
                                                                      ?.confirmed
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "p",
                                                          [
                                                            _c("DeliveryStep", {
                                                              attrs: {
                                                                step: "confirmed",
                                                              },
                                                              on: {
                                                                confirm:
                                                                  _vm.handleDeliveryStep,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "point" },
                                              [_c("span")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "event",
                                            class: {
                                              done: _vm.order.delivery
                                                ?.arrived_at_restaurant,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "circle" },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Arrived at the restaurant"
                                                  ),
                                                ]),
                                                _vm.order.delivery
                                                  ?.arrived_at_restaurant
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.humanizeDateDifference(
                                                                _vm.order
                                                                  .delivery
                                                                  ?.created_at_db,
                                                                _vm.order
                                                                  .delivery
                                                                  ?.arrived_at_restaurant
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              new Date(
                                                                _vm.order.delivery?.arrived_at_restaurant
                                                              ).toLocaleString()
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("DeliveryStep", {
                                                      attrs: {
                                                        step: "arrived-at-restaurant",
                                                      },
                                                      on: {
                                                        confirm:
                                                          _vm.handleDeliveryStep,
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "point" },
                                              [
                                                _vm.order.delivery
                                                  ?.arrived_at_restaurant
                                                  ? _c("span")
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "event",
                                            class: {
                                              done: _vm.order.delivery
                                                ?.picked_up,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "circle" },
                                              [
                                                _c("h4", [
                                                  _vm._v("Picked up "),
                                                  _vm.order.courierPickupCode
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                _vm.order
                                                                  .courierPickupCode
                                                              ) +
                                                              ")"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                                _vm.order.delivery?.picked_up
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.humanizeDateDifference(
                                                                _vm.order
                                                                  .delivery
                                                                  ?.arrived_at_restaurant,
                                                                _vm.order
                                                                  .delivery
                                                                  ?.picked_up
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              new Date(
                                                                _vm.order.delivery?.picked_up
                                                              ).toLocaleString()
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("DeliveryStep", {
                                                      attrs: {
                                                        step: "picked-up",
                                                      },
                                                      on: {
                                                        confirm:
                                                          _vm.handleDeliveryStep,
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "point" },
                                              [
                                                _vm.order.delivery?.picked_up
                                                  ? _c("span")
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "event",
                                            class: {
                                              done: _vm.order.delivery
                                                ?.arrived_at_customer,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "circle" },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Arrived at the customer"
                                                  ),
                                                ]),
                                                _vm.order.delivery
                                                  ?.arrived_at_customer
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.humanizeDateDifference(
                                                                _vm.order
                                                                  .delivery
                                                                  ?.picked_up,
                                                                _vm.order
                                                                  .delivery
                                                                  ?.arrived_at_customer
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              new Date(
                                                                _vm.order.delivery?.arrived_at_customer
                                                              ).toLocaleString()
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("DeliveryStep", {
                                                      attrs: {
                                                        step: "arrived-at-customer",
                                                      },
                                                      on: {
                                                        confirm:
                                                          _vm.handleDeliveryStep,
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "point" },
                                              [
                                                _vm.order.delivery
                                                  ?.arrived_at_customer
                                                  ? _c("span")
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "event",
                                            class: {
                                              done: _vm.order.delivery
                                                ?.delivered,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "circle" },
                                              [
                                                _c("h4", [_vm._v("Delivered")]),
                                                _vm.order.delivery?.delivered
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.humanizeDateDifference(
                                                                _vm.order
                                                                  .delivery
                                                                  ?.arrived_at_customer,
                                                                _vm.order
                                                                  .delivery
                                                                  ?.delivered
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              new Date(
                                                                _vm.order.delivery?.delivered
                                                              ).toLocaleString()
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("DeliveryStep", {
                                                      attrs: {
                                                        step: "delivered",
                                                      },
                                                      on: {
                                                        confirm:
                                                          _vm.handleDeliveryStep,
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "point" },
                                              [
                                                _vm.order.delivery?.delivered
                                                  ? _c("span")
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end mb-3" },
                        [
                          _c(
                            "CButtonToolbar",
                            { attrs: { "aria-label": "Order Tracking Link" } },
                            [
                              _c(
                                "em",
                                { staticClass: "text-muted align-self-center" },
                                [_vm._v("Tracking Link:")]
                              ),
                              _c(
                                "CButtonGroup",
                                { staticClass: "mx-1", attrs: { size: "sm" } },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      attrs: { color: "light" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyTextToClipboard(
                                            _vm.orderTrackingLink
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-copy" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "light",
                                        href: _vm.orderTrackingLink,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-external-link" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "CToaster",
                            {
                              staticClass: "mb-5 mr-4",
                              attrs: {
                                position: "bottom-right",
                                autohide: 3000,
                              },
                            },
                            _vm._l(_vm.toasts, function (toast) {
                              return _c(
                                "CToast",
                                {
                                  key: "toast" + toast,
                                  attrs: { show: true, color: "success" },
                                },
                                [_vm._v("✓ Copied!")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.order.roUnderControl == 1
                        ? _c(
                            "CAlert",
                            {
                              staticClass: "p-2",
                              attrs: { show: "", color: "warning" },
                            },
                            [_vm._v(" 👁️ Under Control ")]
                          )
                        : _vm._e(),
                      _vm.order.email_show == 1
                        ? _c(
                            "CAlert",
                            {
                              staticClass: "p-2",
                              attrs: { show: "", color: "success" },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: {
                                  height: 24,
                                  name: "cil-envelope-open",
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  "Email checked at " +
                                    _vm._s(_vm.order.email_show_date)
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "CTabs",
                {
                  staticClass: "tab-menu",
                  attrs: {
                    "active-tab": _vm.activeTab,
                    variant: "pills",
                    vertical: {
                      navs: "col-xl-2 col-md-3",
                      content: "col-xl-10 col-md-9",
                    },
                  },
                  on: {
                    "update:activeTab": function ($event) {
                      _vm.activeTab = $event
                    },
                    "update:active-tab": function ($event) {
                      _vm.activeTab = $event
                    },
                  },
                },
                [
                  _c(
                    "CTab",
                    { attrs: { active: "" } },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-notes" } }),
                          _vm._v(" Details "),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        { staticClass: "mt-2 mt-md-0" },
                        [
                          _c("CCol", { attrs: { xl: "6", col: "12" } }, [
                            _c("div", [
                              _c(
                                "p",
                                { staticClass: "lead font-weight-bold" },
                                [_vm._v("Details")]
                              ),
                              _c("ul", { staticClass: "pl-4" }, [
                                _c("li", { staticClass: "text-danger" }, [
                                  _vm._v("Gevraagde "),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.order.roPickup
                                          ? "<b>afhaal</b>"
                                          : "lever"
                                      ),
                                    },
                                  }),
                                  _vm._v(
                                    "tijd: " +
                                      _vm._s(_vm.order.roDesiredDelivireyTime)
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Subtotaal: " +
                                      _vm._s(
                                        _vm._f("toCurrency")(_vm.order.roPrice)
                                      )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Bezorgkosten: " +
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm.order.roDeliveryPrice
                                        )
                                      )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Totaal restaurant: " +
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm.order.roDeliveryPrice +
                                            _vm.order.roPrice
                                        )
                                      )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Handelingskosten: " +
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm.order.roHandlingfee
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Fooi: " +
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm.order.roCourierTip
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("ul", { staticClass: "pl-4" }, [
                                _c("li", [
                                  _vm._v(" Betalingswijze: "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.order.roPaysWith)),
                                  ]),
                                  _vm.order.roPaysWith2
                                    ? _c("span", [
                                        _vm._v(" and "),
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.order.roPaysWith2)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.order.roPaysCache > 0
                                          ? " Wilt betalen met " +
                                              _vm.order.roPaysCache
                                          : ""
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.order.roPaysCache < 0
                                          ? _vm.order.roPymntVfd == 1
                                            ? " Betaling ontvangen"
                                            : " Betaling NOG NIET ontvangen"
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("CCol", { attrs: { xl: "6", col: "12" } }, [
                            _c("div", { staticClass: "mt-4 mt-xl-0" }, [
                              _c(
                                "p",
                                { staticClass: "lead font-weight-bold" },
                                [_vm._v("Restaurant")]
                              ),
                              _vm.order.restaurant
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-items-start" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "c-avatar mr-2" },
                                        [
                                          _c("CImg", {
                                            staticClass: "c-avatar-img",
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: _vm.order.restaurant
                                                .restaurant_logo_path,
                                              placeholderColor: "lightgray",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "CLink",
                                            {
                                              staticClass: "text-muted",
                                              attrs: {
                                                to: {
                                                  name: "View Restaurant",
                                                  params: {
                                                    id: _vm.order.restaurant.id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.order.restaurant
                                                    .restaurant_name
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.order.restaurant
                                                    .restaurant_city
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.order.restaurant
                                                    .restaurant_postcode
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "restaurant-tel" },
                                            [
                                              _vm.order.restaurant
                                                .restaurant_tel
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "tel://" +
                                                          _vm.order.restaurant
                                                            .restaurant_tel,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.restaurant
                                                            .restaurant_tel
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.order.restaurant
                                                .restaurant_gsm
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "tel://" +
                                                          _vm.order.restaurant
                                                            .restaurant_gsm,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.restaurant
                                                            .restaurant_gsm
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.order.restaurant.eigenaar1_gsm
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "tel://" +
                                                          _vm.order.restaurant
                                                            .eigenaar1_gsm,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.restaurant
                                                            .eigenaar1_gsm
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.order.restaurant.eigenaar2_gsm
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "tel://" +
                                                          _vm.order.restaurant
                                                            .eigenaar2_gsm,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.restaurant
                                                            .eigenaar2_gsm
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm.order._details_html
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "lead font-weight-bold" },
                                      [_vm._v("Notes")]
                                    ),
                                    _vm.order._details_html.notes
                                      ? _c(
                                          "CAlert",
                                          {
                                            attrs: { show: "", color: "info" },
                                          },
                                          [
                                            _c("CIcon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                height: 24,
                                                name: "cil-info",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.order._details_html.notes
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "ul",
                                      { staticClass: "pl-4" },
                                      _vm._l(
                                        _vm.order._details_html.details,
                                        function (detail) {
                                          return _c("li", { key: detail }, [
                                            _vm._v(" " + _vm._s(detail) + " "),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "mt-4 list-group-item shadow" },
                        [
                          _c("p", { staticClass: "lead font-weight-bold" }, [
                            _vm._v("Messages"),
                          ]),
                          _vm.order.messages?.length
                            ? _c("Messages", {
                                attrs: { messages: _vm.order.messages },
                              })
                            : _c("span", [_vm._v("No messages.")]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-check-alt" } }),
                          _vm._v(" Confirm "),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "mt-2 mt-md-0" },
                        [
                          _c("p", { staticClass: "lead font-weight-bold" }, [
                            _vm._v("Internal Notes"),
                          ]),
                          _vm.internNotes
                            ? _c(
                                "CAlert",
                                { attrs: { show: "", color: "success" } },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "pl-4 mb-0" },
                                    _vm._l(_vm.internNotes, function (note) {
                                      return _c("li", { key: note }, [
                                        _vm._v(" " + _vm._s(note) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column flex-sm-row" },
                            [
                              _c("CTextarea", {
                                staticClass: "flex-grow-1",
                                attrs: {
                                  label: "Internal Note Only",
                                  placeholder:
                                    "Please input so much information",
                                  lazy: false,
                                  value: _vm.$v.form.internNote.$model,
                                  rows: "3",
                                  isValid: _vm.checkIfValid("internNote"),
                                  invalidFeedback:
                                    "Please input so much information",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.internNote,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c(
                                "CButton",
                                {
                                  staticClass: "h-100 mt-175 ml-050",
                                  attrs: {
                                    size: "sm",
                                    color: "success",
                                    variant: "outline",
                                    shape: "pill",
                                    disabled: _vm.submitted,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDialog("addNote")
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", { attrs: { name: "cil-plus" } }),
                                  _vm._v("Add Note "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "mt-4 list-group-item shadow" },
                        [
                          _c("p", { staticClass: "lead font-weight-bold" }, [
                            _vm._v("Confirm"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column flex-sm-row justify-content-between flex-gap-3",
                            },
                            [
                              _vm.order.roDesiredDelivireyTime == "asap" &&
                              !_vm.confirmationTime
                                ? _c("CSelect", {
                                    attrs: {
                                      label: "Delivery Time",
                                      description: "(Minute)",
                                      options: [15, 30, 45, 60],
                                      custom: "",
                                      value: _vm.$v.form.deliveryTime.$model,
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.deliveryTime,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticStyle: { display: "block" } },
                                        [_vm._v("Approx Delivery Time")]
                                      ),
                                      _c("vue-timepicker", {
                                        attrs: {
                                          format: "HH:mm",
                                          "minute-interval": 5,
                                          "close-on-complete": "",
                                          "hide-clear-button": "",
                                          "auto-scroll": "",
                                        },
                                        model: {
                                          value:
                                            _vm.$v.form.approxDeliveryTime
                                              .$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.form.approxDeliveryTime,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.form.approxDeliveryTime.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                              _vm.order.roAproxDeliveryTimeToCourier
                                ? _c(
                                    "div",
                                    { staticClass: "form-group flex-grow-1" },
                                    [
                                      _c(
                                        "label",
                                        { staticStyle: { display: "block" } },
                                        [_vm._v("Delivery Time To Courier")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-primary p-2",
                                          staticStyle: {
                                            "font-size": "1.25rem",
                                            "letter-spacing": "0.2rem",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.order
                                                  .roAproxDeliveryTimeToCourier
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group mt-3" },
                            [
                              _c("CInputCheckbox", {
                                attrs: {
                                  label: "Send SMS",
                                  description:
                                    "Default confirmation message will be sent to customer",
                                  custom: "",
                                  lazy: false,
                                  checked: _vm.$v.form.sendSms.$model,
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.sendSms,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "info",
                                disabled: _vm.submitted || !!_vm.order.delivery,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDialog("confirm")
                                },
                              },
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-check-alt" } }),
                              _vm._v(" Confirm "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-euro" } }),
                          _vm._v(" Payments "),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c("CCol", { attrs: { xl: "6", col: "12" } }, [
                            _vm.order.online_payments &&
                            _vm.order.online_payments.length > 0
                              ? _c(
                                  "section",
                                  { staticClass: "mt-2 mt-md-0" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "lead font-weight-bold" },
                                      [_vm._v("Online Pay Attempts")]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "pl-4" },
                                      [
                                        _vm._l(
                                          _vm.order.online_payments,
                                          function (payment) {
                                            return _c(
                                              "li",
                                              { key: payment.id },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("toCurrency")(
                                                        payment.amount
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" | "),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("ucfirst")(
                                                        payment.status
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(payment.provider) +
                                                    " "
                                                ),
                                                _c("small", [
                                                  _vm._v(
                                                    "(#" +
                                                      _vm._s(
                                                        payment.payment_id
                                                      ) +
                                                      ")"
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm.order.roRefundedVal
                                          ? _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "alert-warning mt-3 p-2",
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    "Refunded amount: " +
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          _vm.order
                                                            .roRefundedVal
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm.order.online_payments.length > 0
                                      ? _c(
                                          "CAlert",
                                          {
                                            staticStyle: { display: "flex" },
                                            attrs: {
                                              show: "",
                                              color: "danger",
                                            },
                                          },
                                          [
                                            _c("CIcon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                height: 36,
                                                name: "cil-bell-exclamation",
                                              },
                                            }),
                                            _vm._v(
                                              " Paid, verified of succeeded is betaald !! Als er niets staat is het niet betaald enkel poging tot betalen "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !!_vm.order.coupons?.length
                              ? _c("section", { staticClass: "mt-4" }, [
                                  _c(
                                    "p",
                                    { staticClass: "lead font-weight-bold" },
                                    [_vm._v("Vouchers:")]
                                  ),
                                  _c(
                                    "ul",
                                    { staticClass: "pl-4" },
                                    _vm._l(
                                      _vm.order.coupons,
                                      function (coupon) {
                                        return _c("li", { key: coupon.code }, [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toCurrency")(
                                                  coupon.discount_amount
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" | "),
                                          _c("span", [
                                            _vm._v(_vm._s(coupon.code)),
                                          ]),
                                          coupon.description
                                            ? _c("small", [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(coupon.description) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                            !!_vm.order.menutepay_transactions?.length
                              ? _c("section", { staticClass: "mt-4" }, [
                                  _c(
                                    "p",
                                    { staticClass: "lead font-weight-bold" },
                                    [_vm._v("MenutePay")]
                                  ),
                                  _c(
                                    "ul",
                                    { staticClass: "pl-4" },
                                    _vm._l(
                                      _vm.order.menutepay_transactions,
                                      function (mp) {
                                        return _c("li", { key: mp.id }, [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toCurrency")(
                                                  parseFloat(mp.value)
                                                )
                                              )
                                            ),
                                          ]),
                                          mp.budget_group?.intern_name
                                            ? _c("small", [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      mp.budget_group
                                                        .intern_name
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("CCol", { attrs: { xl: "6", col: "12" } }, [
                            _c(
                              "section",
                              {
                                staticClass: "mt-4 mt-xl-0 d-flex flex-column",
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "lead font-weight-bold" },
                                  [_vm._v("Payment Breakdown Chart")]
                                ),
                                _c("CChartPie", {
                                  attrs: {
                                    datasets: _vm.allPayments,
                                    labels: [
                                      "MenutePay",
                                      "Voucher",
                                      "Online Payment",
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  !!_vm.order.deliveryservice
                    ? _c(
                        "CTab",
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("CIcon", { attrs: { name: "cil-history" } }),
                              _vm._v(" Delivery History "),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "delivery-history" }, [
                            _c(
                              "div",
                              { staticClass: "timeline-wrapper" },
                              _vm._l(
                                _vm.deliveryHistory,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "timeline-item",
                                      class: {
                                        rejected:
                                          item.rejected_at &&
                                          item.rejected_by?.type === "reject",
                                        revoked:
                                          item.rejected_at &&
                                          item.rejected_by?.type === "revoke",
                                        delivered: item.delivered_at,
                                        active:
                                          !item.rejected_at &&
                                          !item.delivered_at,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "timeline-badge",
                                          class: {
                                            "bg-danger":
                                              item.rejected_at &&
                                              item.rejected_by?.type ===
                                                "reject",
                                            "bg-warning":
                                              item.rejected_at &&
                                              item.rejected_by?.type ===
                                                "revoke",
                                            "bg-success": item.delivered_at,
                                            "bg-info":
                                              !item.rejected_at &&
                                              !item.delivered_at,
                                          },
                                        },
                                        [
                                          item.rejected_at
                                            ? _c("CIcon", {
                                                attrs: { name: "cil-x-circle" },
                                              })
                                            : item.delivered_at
                                            ? _c("CIcon", {
                                                attrs: {
                                                  name: "cil-check-circle",
                                                },
                                              })
                                            : _c("CIcon", {
                                                attrs: { name: "cil-truck" },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "timeline-panel" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "timeline-heading" },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "timeline-title mb-2",
                                                },
                                                [
                                                  item.rejected_at
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              "text-danger":
                                                                item.rejected_by
                                                                  ?.type ===
                                                                "reject",
                                                              "text-warning":
                                                                item.rejected_by
                                                                  ?.type ===
                                                                "revoke",
                                                            },
                                                          },
                                                          [
                                                            _c("CIcon", {
                                                              attrs: {
                                                                name:
                                                                  item
                                                                    .rejected_by
                                                                    ?.type ===
                                                                  "reject"
                                                                    ? "cil-x-circle"
                                                                    : "cil-ban",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item
                                                                    .rejected_by
                                                                    ?.title
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : item.delivered_at
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-success",
                                                          },
                                                          [
                                                            _c("CIcon", {
                                                              attrs: {
                                                                name: "cil-check-circle",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " Delivered "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-info",
                                                          },
                                                          [
                                                            _c("CIcon", {
                                                              attrs: {
                                                                name: "cil-truck",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item
                                                                    .assigned_to
                                                                    ?.title
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "timeline-body" },
                                            [
                                              item.assigned_to?.user ||
                                              item.rejected_by?.user
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center mb-2",
                                                    },
                                                    [
                                                      item.assigned_to?.user
                                                        ? [
                                                            _c("CImg", {
                                                              staticClass:
                                                                "c-avatar-img mr-2",
                                                              staticStyle: {
                                                                width: "24px",
                                                                height: "24px",
                                                              },
                                                              attrs: {
                                                                src:
                                                                  item
                                                                    .assigned_to
                                                                    .user
                                                                    .image_icon ||
                                                                  item
                                                                    .assigned_to
                                                                    .user
                                                                    .gravatar,
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item
                                                                    .assigned_to
                                                                    .user
                                                                    .first_name
                                                                ) +
                                                                  " (#" +
                                                                  _vm._s(
                                                                    item
                                                                      .assigned_to
                                                                      .user.id
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ]),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "timeline-date text-muted",
                                                },
                                                [
                                                  item.rejected_at
                                                    ? _c("small", [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Rejected at:"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              new Date(
                                                                item.rejected_at
                                                              ).toLocaleString()
                                                            )
                                                        ),
                                                      ])
                                                    : item.delivered_at
                                                    ? _c("small", [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Delivered at:"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              new Date(
                                                                item.delivered_at
                                                              ).toLocaleString()
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("small", [
                                                    _c("b", [
                                                      _vm._v("Assigned at:"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          new Date(
                                                            item.assigned_at ||
                                                              item.created_at
                                                          ).toLocaleString()
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardFooter",
        { staticClass: "sticky d-flex justify-content-between" },
        [
          _c(
            "div",
            { staticClass: "vertical-center flex-gap-2" },
            [
              _c(
                "CButton",
                {
                  directives: [
                    {
                      name: "c-tooltip",
                      rawName: "v-c-tooltip",
                      value: { content: "Order Activities" },
                      expression: "{ content: 'Order Activities' }",
                    },
                  ],
                  attrs: {
                    size: "sm",
                    color: "dark",
                    variant: "ghost",
                    shape: "pill",
                  },
                  on: {
                    click: () =>
                      _vm.$store.commit("set", [
                        "orderActivityLogsAsideShow",
                        true,
                      ]),
                  },
                },
                [
                  _c("CIcon", {
                    attrs: { name: "cil-fingerprint", size: "xl" },
                  }),
                ],
                1
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    color: "warning",
                    disabled: _vm.order.roUnderControl == 1 || _vm.submitted,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDialog("takeControl")
                    },
                  },
                },
                [_vm._v(" 👁️ Take Control ")]
              ),
            ],
            1
          ),
          _c(
            "CDropdown",
            {
              attrs: {
                color: "danger",
                disabled: _vm.order.roDeleted == 1 || _vm.submitted,
              },
              scopedSlots: _vm._u([
                {
                  key: "toggler-content",
                  fn: function () {
                    return [
                      _c("CIcon", { attrs: { name: "cil-trash" } }),
                      _vm._v(" Delete "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("CDropdownItem", { attrs: { disabled: "" } }, [
                _vm._v("👇 Please choose the reason:"),
              ]),
              _c("CDropdownDivider"),
              _c(
                "CDropdownItem",
                {
                  on: {
                    click: function ($event) {
                      return _vm.confirmDialog("deleteOrder", 0)
                    },
                  },
                },
                [_vm._v("Test Order")]
              ),
              _c(
                "CDropdownItem",
                {
                  on: {
                    click: function ($event) {
                      return _vm.confirmDialog("deleteOrder", -1)
                    },
                  },
                },
                [_vm._v("Fake Order")]
              ),
              _c(
                "CDropdownItem",
                {
                  on: {
                    click: function ($event) {
                      return _vm.confirmDialog("deleteOrder", 1)
                    },
                  },
                },
                [_vm._v("Canceled by Customer")]
              ),
              _c(
                "CDropdownItem",
                {
                  on: {
                    click: function ($event) {
                      return _vm.confirmDialog("deleteOrder", 2)
                    },
                  },
                },
                [_vm._v("Restaurant Closed")]
              ),
              _vm.order.deliveryservice
                ? [
                    _c(
                      "CDropdownItem",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.confirmDialog("deleteOrder", -2)
                          },
                        },
                      },
                      [_vm._v("No Courier")]
                    ),
                    _c(
                      "CDropdownItem",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.confirmDialog("deleteOrder", 3)
                          },
                        },
                      },
                      [_vm._v("No Courier (Pay to Restaurant)")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
      _c("TransferOrRevokeOrder", {
        attrs: {
          courierId: _vm.order.delivery?.courier?.id,
          dispatcherId: _vm.order.delivery?.dispatcher?.id,
          order: _vm.$globalFunc.extractKeys(_vm.order, [
            "id",
            "roDesiredDelivireyTime",
            "roAproxDeliveryDateToCourier",
            "roAddress",
            "roCity",
            "roPostal_code",
            "roCountryId",
          ]),
          restaurant: _vm.$globalFunc.extractKeys(_vm.order?.restaurant, [
            "id",
            "restaurant_postcode",
          ]),
        },
        on: {
          transferred: function ($event) {
            return _vm.getForm(_vm.itemId)
          },
        },
      }),
      _c("OrderActivityLogs", { attrs: { order: _vm.order } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }